export const COLORS = {
  white: '#FFF',
  lightWhite: '#E4E4F0',
  semiWhite: 'rgba(255,255,240,0.25)',
  lightBlue: '#50CAF9',
  blue: '#5390F0',
  darkBlue: '#396CB7',
  gray: '#777',
  lightGray: '#CCC',
  black: '#494a66',
  gold: '#ffbd0a',
  semiGold: 'rgba(255,189,10,0.5)',
}

export const FONTS = {
  Lato: 'Lato',
}

export const FONT_SIZES = {
  xsmall: '12px',
  small: '14px',
  medium: '16px',
  large: '18px',
  xlarge: '22px',
  xxlarge: '30px',
  huge: '48px',
}

export const FONT_WEIGHTS = {
  normal: 400,
  bold: 700,
}

export const MARGIN = {
  default: '120px 145px',
  small: '50px 60px',
}

export const SHADOW =
  '0px 2px 20px 0px rgba(0,0,0,0.075), 0px 4px 30px 0px rgba(0,0,0,0.025)'

export const EASING = 'cubic-bezier(0.645, 0.045, 0.355, 1)'
export const TRANSITION = 'all 0.25s cubic-bezier(0.645, 0.045, 0.355, 1)'

export const BORDER_RADIUS = {
  button: '10px',
  card: '20px',
}

export const HEADER_HEIGHT = '100px'

export const GRADIENT = `linear-gradient(to bottom, #3466AE, #5390F1)`

export const MEDIA_QUERIES = {
  tiny: 450,
  mobile: 850,
  desktop: 1200,
}
